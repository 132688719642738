export default [
    {
    header: 'Development',
  },
  {
    title: 'Ressourcen',
    icon: 'CodeIcon',
    children: [
      {
        title: 'Azure DevOps',
        href: 'https://dev.azure.com/elko-projects/Der-Lagermeister',
        icon: 'FileTextIcon',
      },
      {
        title: 'Strapi',
        href: 'https://strapi.io/',
        icon: 'FileTextIcon',
      },
      {
        title: 'Strapi Docs',
        href: 'https://strapi.io/resource-center',
        icon: 'FileTextIcon',
      },
      {
        title: 'Vue',
        href: 'https://vuejs.org/',
        icon: 'FileTextIcon',
        children: [
          {
            title: 'Vue Cheat Sheet Vue Mastery',
            href: 'https://www.vuemastery.com/pdf/Vue-Cheat-Sheet.pdf',
            icon: 'FileTextIcon',
          },
          {
            title: 'Vue Cheat Sheet DevHints',
            href: 'https://devhints.io/vue',
            icon: 'FileTextIcon',
          },
          {
            title: 'Vue Cheat Sheet GitHub',
            href: 'https://github.com/dekadentno/vue-cheat-sheet',
            icon: 'FileTextIcon',
          },
        ],
      },
      {
        title: 'BootstrapVue',
        href: 'https://bootstrap-vue.org/',
        icon: 'FileTextIcon',
      },
      {
        title: 'Vuexy Template',
        href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/login',
        icon: 'FileTextIcon',
      },
      {
        title: 'Vuexy Docs',
        href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/',
        icon: 'FileTextIcon',
      },

    ],
  },

]
