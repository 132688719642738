export default [
   {
    header: 'Dashboards',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
]
