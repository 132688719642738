export default [
    {
    header: 'Lagereinheiten',
  },
  {
    title: 'Lager',
    route: 'storages-list',
    icon: 'BoxIcon',
  },
  {
    title: 'Standorte',
    route: 'locations-list',
    icon: 'MapIcon',
  },
]
