export default [
  {
    header: 'Feedback',
  },
  {
    title: 'Feedback melden',
    route: 'feedback',
    icon: 'MessageSquareIcon',
  },
]
