export default [
{
    header: 'Links',
  },
  {
    title: 'Der Lagermeister',
    href: 'https://der-lagermeister.de/',
    icon: 'ExternalLinkIcon',
  },
]
