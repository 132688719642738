export default [
    
    {
    header: 'Chancen',
  },
  {
    title: 'Anfragen',
    route: 'inquiries-list',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Angebote',
    route: 'offers-list',
    icon: 'ShoppingCartIcon',
  },
]
