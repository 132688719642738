export default [
{
    header: 'Einnahmen',
  },
  {
    title: 'Kunden',
    route: 'customers-list',
    icon: 'UsersIcon',
  },
  {
    title: 'Buchungen',
    route: 'orders-list',
    icon: 'ShoppingCartIcon',
  },
]
