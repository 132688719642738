export default [
   {
    header: 'Verwaltung',
  },
  {
    title: 'Benutzer',
    route: 'users-list',
    icon: 'UserIcon',
  },
  {
    title: 'Einstellungen',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
